'use strict';

Gri.module({
  name: 'video-alpha',
  ieVersion: null,
  $el: $('.video-alpha'),
  container: '.video-alpha',
  fn: function () {

    const $this = this.$el;
    let videoContent = $this.find('.video-alpha-modal-content').find('video')[0];

    $this.find('img').on('click', function () {
      $(window).trigger('modal-video', {content: videoContent});
    });

  }
});
